import { Link } from "react-router-dom";
import styles from "./BlogCard.module.css";
import { useBlog } from "../../contexts";

export const BlogCard = ({ blog }) => {
	const { handleBlogSelection } = useBlog();

	return (
		<div className={styles.cardContainer}>
			<div className={styles.heading}>{blog.title}</div>
			<p>
				{blog.body[0].type === "paragraph"
					? blog.body[0].value
					: blog.body[1].value}
			</p>
			<Link to={`${blog.title.replace(/\s+/g, "_")}`}>
				<button
					onClick={() => {
						handleBlogSelection(blog);
					}}
				>
					Read More
				</button>
			</Link>
		</div>
	);
};
