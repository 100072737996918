import { useLocation } from "react-router-dom";
import styles from "./Footer.module.css";
export const Footer = () => {
	const location = useLocation();
	return (
		<>
			{location.pathname.split("/").length < 4 && (
				<div className={styles.footerContainer}>
					2023 © Copyrights Intech Additive Solutions | All Rights Reserved |
				</div>
			)}
		</>
	);
};
