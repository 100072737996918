import { createContext, useContext, useReducer } from "react";

const BlogContext = createContext();

export const useBlog = () => useContext(BlogContext);

export const BlogProvider = ({ children }) => {
	const reducer = (state, action) => {
		switch (action.type) {
			case "SET":
				sessionStorage.setItem("blogState", JSON.stringify(action.payload));
				return action.payload;
			default:
				return state;
		}
	};

	const initialState = JSON.parse(sessionStorage.getItem("blogState")) ?? {};

	const [selectedBlog, dispatchBlog] = useReducer(reducer, initialState);

	const handleBlogSelection = (course) => {
		dispatchBlog({ type: "SET", payload: course });
	};

	return (
		<BlogContext.Provider
			value={{
				selectedBlog,
				handleBlogSelection,
			}}
		>
			{children}
		</BlogContext.Provider>
	);
};
