import { Outlet } from "react-router-dom";
import { Navbar, Footer } from "../index";
import styles from "./PageWrapper.module.css";

export const PageWrapper = () => {
	return (
		<div className={styles.pageWrapper}>
			<Navbar />
			<Outlet />
			<Footer />
		</div>
	);
};
