import ReactPlayer from "react-player";
import { useCourse } from "../../contexts";

export const VideoPlayer = ({ playerRef, handleSeek, url }) => {
	const { handleSeekTime } = useCourse();

	return (
		<ReactPlayer
			ref={playerRef}
			style={{ margin: "0 auto" }}
			url={url}
			controls={true}
			config={{
				file: {
					attributes: {
						controlsList: "nodownload",
						disablePictureInPicture: true,
					},
				},
			}}
			onPause={() => {
				handleSeekTime(playerRef.current.getCurrentTime());
			}}
			onReady={() => {
				handleSeek();
			}}
			width="75%"
			height="100%"
		/>
	);
};
