import styles from "./Dropdown.module.css";
import { MdExpandMore } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { useCourse } from "../../contexts/CourseContext";

export const Dropdown = () => {
	const { course, handleVideoSelection } = useCourse();
	const location = useLocation();

	//Function to toggle collapsable chapters list
	const toggleAccordion = (panelToActivate) => {
		const activeButton = panelToActivate.querySelector("button");
		const activePanelIsOpened = activeButton.getAttribute("aria-expanded");

		if (activePanelIsOpened === "true") {
			panelToActivate
				.querySelector("button")
				.setAttribute("aria-expanded", false);

			panelToActivate
				.querySelector(`.${styles.accordionContent}`)
				.setAttribute("aria-hidden", true);
		} else {
			panelToActivate
				.querySelector("button")
				.setAttribute("aria-expanded", true);

			panelToActivate
				.querySelector(`.${styles.accordionContent}`)
				.setAttribute("aria-hidden", false);
		}
	};

	return (
		<div className={styles.accordionContainer}>
			<div
				className={styles.accordion}
				onClick={(e) => {
					if (e.target.closest(`.${styles.panelContainer}`)) {
						const activePanel = e.target.closest(`.${styles.accordionPanel}`);
						if (!activePanel) return;
						toggleAccordion(activePanel);
					}
				}}
			>
				{course.selectedCourse.Modules?.sort((a, b) => {
					const nameA = a.Module_Name?.toLowerCase();
					const nameB = b.Module_Name?.toLowerCase();
					if (nameA < nameB) {
						return -1;
					}
					if (nameA > nameB) {
						return 1;
					}
					return 0;
				}).map((module, index) => (
					<div
						className={styles.accordionPanel}
						key={index + 2}
					>
						<div className={styles.panelContainer}>
							<button
								className={styles.accordionTrigger}
								aria-expanded={index === 0 ? "true" : "false"}
								aria-controls={`accordion${index + 1}-content`}
							>
								<div className={styles.panelTitleContainer}>
									<div className={styles.panelIndex}>{index + 1} </div>
									<div className={styles.panelHeader}>
										<div className={styles.panelTitle}>
											{module.Module_Name}
										</div>
										<div className={styles.panelSubtitle}>
											{module.Videos.length} Chapters
										</div>
									</div>
								</div>
								<MdExpandMore />
							</button>
						</div>
						<div
							className={styles.accordionContent}
							role="region"
							aria-labelledby={`panel${0 + 1}-title`}
							aria-hidden={index !== 0 ? "true" : "false"}
							id={`panel${0 + 1}-content`}
						>
							<div className={styles.checkboxContainer}>
								{module.Videos.sort((a, b) => {
									const nameA = a.name?.toLowerCase();
									const nameB = b.name?.toLowerCase();
									if (nameA < nameB) {
										return -1;
									}
									if (nameA > nameB) {
										return 1;
									}
									return 0;
								}).map((video, videoIndex) => {
									return (
										<div
											onClick={() => {
												handleVideoSelection(video, module);
											}}
											key={videoIndex + 8}
										>
											<Link
												to={`/Courses/${course.selectedCourse.name.replace(
													/\s+/g,
													"_"
												)}/${video.name.replace(/\s+/g, "_")}`}
												className={`${styles.checkbox} ${
													course.selectedVideo?.name === video.name &&
													location.pathname !==
														`/Courses/${course.selectedCourse.name.replace(
															/\s+/g,
															"_"
														)}`
														? styles.active
														: null
												}`}
											>
												<input
													type="checkbox"
													key={index + 2}
													checked={
														course.watchHistory[video.id]?.started ?? false
													}
													readOnly
												/>
												<label className={styles.checkboxLabel}>
													{video.name}
												</label>
											</Link>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
