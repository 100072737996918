import { BlogCard } from "../../components";
import { BlogsData } from "../../API/BlogsData";
import styles from "./Blogs.module.css";
export const Blogs = () => {
	return (
		<div className={styles.blogsContainer}>
			{BlogsData.map((blog) => (
				<BlogCard
					blog={blog}
					key={blog.id}
				/>
			))}
		</div>
	);
};
