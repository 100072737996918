import { Route, Routes } from "react-router-dom";
import { PageWrapper } from "./components";
import {
	Articles,
	Blogs,
	Courses,
	Homepage,
	Newsfeed,
	CourseDetails,
	VideoPage,
	BlogPage,
} from "./pages";
import "./App.css";

function App() {
	return (
		<Routes>
			<Route element={<PageWrapper />}>
				<Route
					path="/"
					element={<Homepage />}
				>
					<Route
						path="Courses"
						element={<Courses />}
					></Route>
					<Route
						path="Blogs"
						element={<Blogs />}
					></Route>
					<Route
						path="Articles"
						element={<Articles />}
					></Route>
					<Route
						path="Newsfeed"
						element={<Newsfeed />}
					></Route>
				</Route>
				<Route
					path="/Courses/:courseName"
					element={<CourseDetails />}
				/>
				<Route
					path="/Courses/:courseName/:videoName"
					element={<VideoPage />}
				/>
				<Route
					path="/Blogs/:blogName"
					element={<BlogPage />}
				/>
			</Route>
		</Routes>
	);
}

export default App;

