import styles from "./VideoTranscript.module.css";

export const VideoTranscript = () => {
	return (
		<div className={styles.transcriptContainer}>
			<p>
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
				voluptatibus, blanditiis omnis beatae enim, dolores consectetur animi
				iste debitis saepe impedit adipisci nisi inventore amet libero ab est
				labore. Soluta?
			</p>
			<p>
				A illum ullam quo deleniti minus nam praesentium unde! Laboriosam
				voluptatum, non rem cumque quae suscipit recusandae similique atque
				dolorem cupiditate eveniet quia at, cum earum fugit perferendis
				perspiciatis veniam.
			</p>
			<p>
				Magni rem ipsum provident voluptate qui fuga, quo fugiat? Quidem, rerum
				fugiat saepe praesentium aliquam nisi quas nihil omnis excepturi
				deleniti quam repudiandae unde inventore voluptates in numquam quis
				quibusdam!
			</p>
			<p>
				Temporibus blanditiis asperiores, expedita assumenda natus sequi! Alias
				necessitatibus similique quia eveniet ipsum soluta impedit saepe?
				Ducimus amet odio pariatur dolorem harum. Recusandae quasi, laboriosam
				obcaecati iusto dolore nesciunt suscipit.
			</p>
			<p>
				Sit similique accusamus sapiente molestias recusandae dolorum eum
				impedit, in laboriosam porro quidem fuga ad ea praesentium harum atque!
				Corporis impedit animi numquam, deleniti repellendus qui dicta maiores
				magnam autem!
			</p>
			<p>
				Magnam unde, voluptatem voluptate repudiandae pariatur tempora dolorem
				accusantium necessitatibus asperiores quisquam commodi, eligendi illum
				at explicabo ut itaque? Provident commodi minima amet eos culpa
				voluptate quidem vel aspernatur in!
			</p>
			<p>
				Aliquam nihil, quibusdam facilis ipsum officia omnis quod perspiciatis
				quisquam, odio neque quam sed pariatur, blanditiis nemo hic fugit
				repudiandae expedita debitis! Dolore, cupiditate debitis? Aliquid quis
				consectetur quod incidunt.
			</p>
			<p>
				Sequi aperiam non hic quia facilis est, unde a itaque qui doloremque
				cupiditate, nostrum voluptatem dolore iusto vitae voluptas velit
				adipisci ullam. Quisquam atque magni repudiandae excepturi
				necessitatibus expedita harum.
			</p>
			<p>
				Molestiae consequuntur temporibus, tempore est cupiditate deserunt qui
				nisi? Tempore repellat ducimus qui ratione excepturi molestiae atque
				recusandae. Unde laboriosam vitae harum? Quaerat molestiae tempore
				praesentium ex odit. At, aspernatur.
			</p>
			<p>
				Assumenda magni sit ut illo placeat corporis fugiat quam enim corrupti
				minus recusandae, libero animi ipsa id quisquam iure autem harum natus
				fugit! Officia temporibus quod ipsa quidem natus beatae!
			</p>
		</div>
	);
};
