import styles from "./VideoPage.module.css";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { MdOutlineWest } from "react-icons/md";
import {
	Certificate,
	VideoDescription,
	Discussion,
	Dropdown,
	VideoTranscript,
} from "../../components";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useCourse } from "../../contexts/CourseContext";
import { VideoPlayer } from "./VideoPlayer";
import AWS from "aws-sdk";

// Configure the AWS SDK with your credentials and region
AWS.config.update({
	accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
	secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
	region: process.env.REACT_APP_AWS_REGION,
});

// Create a new S3 instance
const s3 = new AWS.S3();

export const VideoPage = () => {
	const { course } = useCourse();
	const [isSidebarOpen, setIsSidebarOpen] = useState(true);
	const [progress, setProgress] = useState(0);
	const [activeTab, setActiveTab] = useState("description");
	const [seek, setSeek] = useState(null);
	const playerRef = useRef(null);
	const [url, setUrl] = useState(null);

	const getVideosFromS3 = () => {
		try {
			const params = {
				Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
				Key: `Courses/${course.selectedCourse.id}/${course.selectedVideo?.id}/${course.selectedVideo?.name}.mp4`,
			};

			const res = s3.getSignedUrl("getObject", params);

			setUrl(res);
		} catch (error) {
			console.error("Error fetching videos from S3:", error);
			throw new Error("Failed to fetch videos from S3.");
		}
	};
	useEffect(() => {
		getVideosFromS3();
	}, [course.selectedVideo]);

	const toggleSideBar = () => {
		if (isSidebarOpen) {
			setIsSidebarOpen(false);
		} else {
			setIsSidebarOpen(true);
		}
	};

	const handleTabClick = (selectedTab) => {
		setActiveTab(selectedTab);
	};

	const handleSeek = () => {
		if (playerRef.current.getCurrentTime() === 0) {
			playerRef.current.seekTo(course.selectedVideo.seek ?? 0);
			setSeek(course.selectedVideo.seek ?? 0);
		}
	};

	const TabsComponent = () => {
		if (activeTab === "description") {
			return <VideoDescription />;
		}
		if (activeTab === "transcript") {
			return <VideoTranscript />;
		}
		if (activeTab === "discussion") {
			return <Discussion />;
		}
		if (activeTab === "certificate") {
			return <Certificate />;
		}
	};

	const handleProgress = () => {
		const arr = course.selectedCourse.Modules.map((module) => {
			return module.Videos.map((video) =>
				course.watchHistory[video.id] ? 1 : 0
			);
		});
		setProgress(
			(arr
				.flat()
				.reduce((acc, curr) => (acc = curr === 1 ? acc + curr : acc), 0) /
				arr.flat().length) *
				100
		);
	};

	useEffect(() => {
		handleProgress();
	});

	return (
		<main
			className={`${styles.mainContainer} ${
				isSidebarOpen ? styles.open : styles.close
			}`}
		>
			<section className={styles.sidebarContainer}>
				<Link
					to={`/Courses/${course.selectedCourse.name?.replace(/\s+/g, "_")}`}
					className={styles.backButton}
				>
					<AiOutlineArrowLeft />
					Back to Course Home
				</Link>
				<div className={styles.courseTitle}>{course.selectedCourse?.name}</div>
				<div className={styles.progressBarContainer}>
					<div
						className={styles.progressPercent}
					>{`${progress}% Completed`}</div>
					<div className={styles.progressBar}>
						<div
							className={styles.progress}
							style={{ width: `${progress}%` }}
						></div>
					</div>
				</div>
				<div className={styles.dropdownContainer}>
					<Dropdown />
				</div>
			</section>
			<section className={styles.contentContainer}>
				<div className={styles.toggleContainer}>
					<MdOutlineWest
						className={styles.sidebarToggle}
						onClick={toggleSideBar}
						aria-expanded={isSidebarOpen}
					/>
				</div>
				<div className={styles.videoPlayer}>
					<VideoPlayer
						playerRef={playerRef}
						handleSeek={handleSeek}
						url={url}
					/>
				</div>
				<div className={styles.bottomContainer}>
					<div className={styles.tabsContainer}>
						<div
							onClick={() => handleTabClick("description")}
							className={activeTab === "description" ? styles.active : ""}
						>
							Description
						</div>
						<div
							onClick={() => handleTabClick("transcript")}
							className={activeTab === "transcript" ? styles.active : ""}
						>
							Transcript
						</div>
						<div
							// onClick={() => handleTabClick("discussion")}
							className={activeTab === "discussion" ? styles.active : ""}
						>
							Discussion
						</div>
						<div
							// onClick={() => handleTabClick("certificate")}
							className={activeTab === "certificate" ? styles.active : ""}
						>
							Certificate
						</div>
					</div>
					<div className={styles.tabsContentContainer}>
						<TabsComponent />
					</div>
				</div>
			</section>
		</main>
	);
};
