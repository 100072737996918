import { createContext, useContext, useReducer } from "react";
import axios from "../API/axios";

const CourseContext = createContext();

export const useCourse = () => useContext(CourseContext);

export const CourseProvider = ({ children }) => {
	const reducer = (state, action) => {
		switch (action.type) {
			case "SET_COURSE":
				sessionStorage.setItem(
					"course",
					JSON.stringify({
						...state,
						selectedCourse: action.payload.course,
						watchHistory: action.payload.watchHistory,
					})
				);
				return {
					...state,
					selectedCourse: action.payload.course,
					watchHistory: action.payload.watchHistory,
				};

			case "UPDATE_VIDEO":
				state.selectedVideo = {
					...state.selectedVideo,
					seek: action.payload.seekTime,
					started: action.payload.started,
				};

				state.watchHistory[state.selectedVideo.id] = {
					seek: action.payload.seekTime,
					started: action.payload.started,
				};

				sessionStorage.setItem(
					"course",
					JSON.stringify({
						...state,
						selectedVideo: {
							...state.selectedVideo,
							seek: action.payload.seekTime,
							started: action.payload.started,
						},
						watchHistory: {
							...state.watchHistory,
							[state.selectedVideo.id]: {
								seek: action.payload.seekTime,
								started: action.payload.started,
							},
						},
					})
				);
				return state;

			case "SET_VIDEO":
				sessionStorage.setItem(
					"course",
					JSON.stringify({
						...state,
						selectedVideo: action.payload.video,
						selectedModule: action.payload.module,
					})
				);
				return {
					...state,
					selectedVideo: action.payload.video,
					selectedModule: action.payload.module,
				};

			case "GET_COURSE":
				sessionStorage.setItem(
					"course",
					JSON.stringify({ ...state, courses: action.payload })
				);
				return { ...state, courses: action.payload };

			default:
				return state;
		}
	};

	const initialState = JSON.parse(sessionStorage.getItem("course")) ?? {};

	const [course, dispatchCourse] = useReducer(reducer, initialState);

	//Screen 1 : Get all Courses
	const fetchCourses = () => {
		axios
			.get("/api/v1/courses", {
				"Content-Type": "application/json",
			})
			.then((response) => {
				dispatchCourse({ type: "GET_COURSE", payload: response.data });
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//Screen 2 : Select Course from Course Card
	const handleCourseSelection = (courseId) => {
		const userId = "60794c11-bcf2-473f-92cd-a437e0fc8490";
		axios
			.get(`/api/v1/user/${userId}/courses/${courseId}`, {
				"Content-Type": "application/json",
			})
			.then((response) => {
				dispatchCourse({
					type: "SET_COURSE",
					payload: {
						course: response.data.data[0],
						watchHistory: response.data.data[1],
					},
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//Screen 3 : Select Video from Course Details or Dropdown
	const handleVideoSelection = (video, module) => {
		const userId = "60794c11-bcf2-473f-92cd-a437e0fc8490";
		const id = video.id;

		axios
			.get(`/api/v1/users/${userId}/videos/${id}`, {
				"Content-Type": "application/json",
			})
			.then((response) => {
				dispatchCourse({
					type: "SET_VIDEO",
					payload: {
						video: {
							...response.data,
							id,
							name: video.name,
						},
						module,
					},
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//Screen 3 : Put seekTime and started of the video
	const handleSeekTime = (time) => {
		const userId = "60794c11-bcf2-473f-92cd-a437e0fc8490";
		const data = {
			[course.selectedCourse.id]: {
				modules: [
					{
						id: course.selectedModule.Module_ID,
						name: course.selectedModule.Module_Name,
						videos: [
							{
								id: course.selectedVideo.id,
								started: true,
								seek_time: time,
							},
						],
					},
				],
			},
		};
		axios
			.put(`/api/v1/users/${userId}/save`, data)
			.then((response) => {
				dispatchCourse({
					type: "UPDATE_VIDEO",
					payload: { seekTime: time, started: true },
				});
			})
			.catch((err) => console.error(err));
	};

	return (
		<CourseContext.Provider
			value={{
				course,
				handleCourseSelection,
				handleVideoSelection,
				handleSeekTime,
				fetchCourses,
			}}
		>
			{children}
		</CourseContext.Provider>
	);
};
