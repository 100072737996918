import styles from "./Homepage.module.css";
import { BsPersonVideo3 } from "react-icons/bs";
import { RiArticleLine } from "react-icons/ri";
import { MdNewspaper } from "react-icons/md";
import { FaBlog } from "react-icons/fa";
// import { Card } from "../../components/Card/Card";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const Homepage = () => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (location.pathname === "/") navigate("/Courses");
	}, []);

	return (
		<>
			<main className={styles.mainContainer}>
				<section className={styles.banner}>
					<h1>Welcome, User!</h1>
				</section>
				<section className={styles.content}>
					<div className={styles.tabsContainer}>
						<NavLink
							to="Courses"
							className={({ isActive }) => (isActive ? styles.active : "")}
						>
							<BsPersonVideo3 /> Courses
						</NavLink>
						<NavLink
							to="Blogs"
							className={({ isActive }) => (isActive ? styles.active : "")}
						>
							<FaBlog style={{ fontSize: "1.6rem" }} />
							Blogs
						</NavLink>
						<NavLink
							to="Articles"
							className={({ isActive }) => (isActive ? styles.active : "")}
						>
							<RiArticleLine />
							Articles
						</NavLink>
						<NavLink
							to="Newsfeed"
							className={({ isActive }) => (isActive ? styles.active : "")}
						>
							<MdNewspaper />
							Newsfeeed
						</NavLink>
					</div>
					<Outlet />
				</section>
			</main>
		</>
	);
};
