import { useEffect } from "react";
import { useBlog } from "../../contexts";
import styles from "./BlogPage.module.css";

export const BlogPage = () => {
	const { selectedBlog } = useBlog();

	const BlogContent = () => {
		return selectedBlog.body.map((object) => {
			switch (object.type) {
				case "paragraph":
					return <p>{object.value}</p>;
				case "heading":
					return <h2>{object.value}</h2>;
				case "list":
					return (
						<ul>
							{object.value.map((item) => (
								<li>{item}</li>
							))}
						</ul>
					);
				default:
					return object;
			}
		});
	};

	useEffect(() => {
		document.title = `${selectedBlog.title} | AM-Varsity `;
	}, []);

	return (
		<main className={styles.blogPageContainer}>
			<div className={styles.title}>{selectedBlog.title}</div>
			<div className={styles.body}>
				<BlogContent />
			</div>
		</main>
	);
};
