import styles from "./CourseCard.module.css";
import AMVarsity from "../../assets/banner.jpg";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useCourse } from "../../contexts/CourseContext";

export const CourseCard = ({ CourseData }) => {
	const { handleCourseSelection } = useCourse();
	return (
		<div className={styles.cardContainer}>
			<div className={styles.imgContainer}>
				<img
					src={AMVarsity}
					alt="AMVarsity"
				/>
			</div>
			<div className={styles.contentContainer}>
				<div className={styles.labelContainer}>
					<h1>{CourseData?.name ?? "Course Name"}</h1>
					<p>{CourseData?.description ?? "Course Description"}</p>
				</div>
				<div className={styles.bottomContainer}>
					<h2>{CourseData["no_of_modules"] ?? 4} Modules</h2>
					<Link
						to={CourseData?.name?.replace(/\s+/g, "_")}
						onClick={() => {
							handleCourseSelection(CourseData.id);
						}}
					>
						Go to Course <AiOutlineArrowRight />
					</Link>
				</div>
			</div>
		</div>
	);
};
