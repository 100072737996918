import { useEffect } from "react";
import { CourseCard } from "../../components";
import styles from "./Courses.module.css";
import { useCourse } from "../../contexts";

export const Courses = () => {
	const { course, fetchCourses } = useCourse();

	useEffect(() => {
		fetchCourses();
		document.title = `AM-Varsity `;
	}, []);

	return (
		<div className={styles.cardsContainer}>
			{course.courses?.map((course, index) => (
				<CourseCard
					key={index + 2}
					CourseData={course}
				/>
			))}
		</div>
	);
};
