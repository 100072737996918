import styles from "./Navbar.module.css";
import varsityLogo from "../../assets/Logos/AMVarsity.png";
import { IoApps } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { AiOutlineUser, AiOutlineShoppingCart } from "react-icons/ai";
import { GrCertificate } from "react-icons/gr";
import { BiLogOut } from "react-icons/bi";
import { ImBooks } from "react-icons/im";
import { useCallback, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import AMCosting from "../../assets/Logos/AMC.png";
import AMExplorer from "../../assets/Logos/AME.png";
import AMOptomet from "../../assets/Logos/AMO.png";
import Intech from "../../assets/Logos/Intech_logo.png";

export const Navbar = () => {
	const [activeClass, setActiveClass] = useState(null);
	const componentRef = useRef(null);

	//Dropdown Data
	const dropdownData = [
		{
			logo: Intech,
			label: "Dashboard",
			link: "/Dashboard",
		},
		{
			logo: AMCosting,
			label: "AMCosting",
			link: "/AMCosting",
		},
		{
			logo: AMExplorer,
			label: "AMExplorer",
			link: "/AMExplorer",
		},
		{
			logo: AMOptomet,
			label: "AMOptomet",
			link: "/AMOptomet",
		},
	];

	//Dropdown Card
	const DropdownCard = ({ label, logo }) => {
		return (
			<NavLink className={styles.dropdownCardContainer}>
				<div className={styles.dropdownCardLogoContainer}>
					<img
						src={logo}
						alt={label}
					/>
				</div>
				<div className={styles.dropdownLabel}>{label}</div>
			</NavLink>
		);
	};

	//Selecting the active class element
	const toggleActiveClass = (element) => {
		if (activeClass === null) {
			document.getElementById(element).classList.toggle(styles.active);
			setActiveClass(element);
		} else if (activeClass === element) {
			document.getElementById(element).classList.toggle(styles.active);
			setActiveClass(null);
		} else {
			document.getElementById(activeClass).classList.toggle(styles.active);
			document.getElementById(element).classList.toggle(styles.active);
			setActiveClass(element);
		}
	};

	//Remove active class if event is outside the component
	const handleOutsideClick = useCallback(
		(event) => {
			// Check whether the click target is within the component or not

			if (
				componentRef.current &&
				!componentRef.current.contains(event.target) &&
				activeClass
			) {
				document.getElementById(activeClass).classList.toggle(styles.active);
				setActiveClass(null);
			}
		},
		[componentRef, activeClass, setActiveClass]
	);

	//Add event listner to remove active class
	useEffect(() => {
		// Add event listener to window object when the component mounts
		window.addEventListener("click", handleOutsideClick);

		// Remove event listener when the component unmounts
		return () => {
			window.removeEventListener("click", handleOutsideClick);
		};
	}, [handleOutsideClick]);

	return (
		<header className={styles.navContainer}>
			<NavLink
				to="/Courses"
				className={styles.logo}
			>
				<img
					src={varsityLogo}
					alt="AM Varsity"
				/>
			</NavLink>
			<nav
				className={styles.navLinks}
				ref={componentRef}
			>
				<ul>
					<li
						id="dropdown"
						onClick={(e) => toggleActiveClass(e.currentTarget.id)}
					>
						<IoApps
							style={{
								fill: `${activeClass === "dropdown" ? "black" : "gray"}`,
								color: `${activeClass === "dropdown" ? "black" : "gray"}`,
							}}
						/>
					</li>
					<li
						id="profile"
						onClick={(e) => toggleActiveClass(e.currentTarget.id)}
					>
						<CgProfile
							style={{
								fill: `${activeClass !== "profile" ? "black" : "gray"}`,
								color: `${activeClass === "profile" ? "black" : "gray"}`,
							}}
						/>
					</li>
				</ul>
				{activeClass === "dropdown" && (
					<div
						className={`${styles.dropdown}  ${
							activeClass ? styles.dropdownActive : ""
						}`}
					>
						<div className={styles.dropdownContent}>
							{dropdownData.map((item, index) => (
								<DropdownCard
									label={item.label}
									logo={item.logo}
									link={item.link}
									key={index + 1}
								/>
							))}
						</div>
					</div>
				)}
				{activeClass === "profile" && (
					<div
						className={`${styles.dropdown}  ${
							activeClass ? styles.dropdownActive : ""
						}`}
					>
						<div className={styles.viewProfile}>
							<div className={styles.profilePicContainer}>
								<CgProfile />
							</div>
							<div className={styles.profileDetails}>
								<div className={styles.profileName}>User Name</div>
								<NavLink className={styles.goToProfile}>View Profile</NavLink>
							</div>
						</div>
						<NavLink className={styles.profileDropdowns}>
							<ImBooks />
							My Learnings
						</NavLink>
						<NavLink className={styles.profileDropdowns}>
							<GrCertificate />
							Ceritficates
						</NavLink>
						<NavLink className={styles.profileDropdowns}>
							<AiOutlineShoppingCart />
							Cart
						</NavLink>
						<NavLink className={styles.profileDropdowns}>
							<BiLogOut />
							Logout
						</NavLink>
					</div>
				)}
			</nav>
		</header>
	);
};
