import React from "react";

export const Articles = () => {
	return (
		<h1
			style={{
				textAlign: "center",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				fontSize: "4rem",
			}}
		>
			Coming soon...
		</h1>
	);
};
