import styles from "./CourseDetails.module.css";
import courseBanner from "../../assets/banner.jpg";
import { Link } from "react-router-dom";
import { Dropdown } from "../../components";
import { useCourse } from "../../contexts/CourseContext";
import { useEffect } from "react";

export const CourseDetails = () => {
	const { course, handleVideoSelection } = useCourse();

	useEffect(() => {
		document.title = `${course.selectedCourse?.name} | AM-Varsity `;
	}, [course]);
	return (
		<>
			<main className={styles.courseDetailsContainer}>
				<section className={styles.bannerContainer}>
					<div className={styles.bannerLabels}>
						<div className={styles.imgContainerBanner}>
							<img
								src={courseBanner}
								alt="Course Banner"
							/>
						</div>
						<div className={styles.courseSubtitle}>
							Interactive Course | Bookmarks
						</div>
						<div className={styles.courseTitle}>
							{course.selectedCourse?.name ?? "courseName"}
						</div>
						<div className={styles.courseDescription}>
							{course.selectedCourse?.description ?? "Course Description"}
						</div>
					</div>
				</section>

				<section className={styles.contents}>
					<div className={styles.leftContainer}>
						<div className={styles.courseOverviewContainer}>
							<div className={styles.courseContentsHeader}>Course Overview</div>
							<div className={styles.courseOverviewPara}>
								<p>
									{course.selectedCourse?.overview ??
										`Lorem ipsum dolor sit amet
									consectetur adipisicing elit. Quasi perferendis et natus.
									Expedita, labore laboriosam. Quaerat repudiandae officiis
									autem beatae illum ipsa necessitatibus, magnam dolor
									repellendus, mollitia minima blanditiis laudantium. Eveniet
									veniam nihil, soluta autem facilis, sit cumque optio neque
									quasi quisquam blanditiis odit molestias repellendus
									asperiores! At ut magni necessitatibus suscipit impedit! A
									commodi vero maiores amet voluptatibus nisi! Blanditiis iste
									totam architecto velit repudiandae nihil tempora beatae in
									nulla, vero, similique sed numquam nesciunt amet cum quibusdam
									maiores hic dolores ad! Sed impedit temporibus ipsum libero
									exercitationem ab. Nesciunt harum aliquam quam molestias ea
									itaque quo nam doloremque illum explicabo inventore qui velit
									tempore at magnam delectus, repudiandae odit? Consequuntur
									magni doloribus sit labore vitae. Eum, necessitatibus ullam!
									Lorem ipsum dolor sit amet consectetur adipisicing elit. Error
									qui pariatur aliquid. Blanditiis quidem quasi accusantium
									tempore atque obcaecati maiores! Accusamus provident, suscipit
									quidem doloremque iusto eligendi! Minima, dicta consequuntur!`}
								</p>
							</div>
						</div>

						<div className={styles.courseContentContainer}>
							<div className={styles.courseContentsHeader}>Course Contents</div>
							{course.selectedCourse && <Dropdown />}
						</div>
					</div>

					<div className={styles.rightContainer}>
						<div className={styles.checkoutContainer}>
							<div className={styles.imgContainerCheckout}>
								<img
									src={courseBanner}
									alt="Course Banner"
								/>
							</div>
							<div className={styles.checkoutLabels}>
								<Link
									to={course?.selectedCourse?.Modules?.sort((a, b) => {
										const nameA = a.Module_Name?.toLowerCase();
										const nameB = b.Module_Name?.toLowerCase();
										if (nameA < nameB) {
											return -1;
										}
										if (nameA > nameB) {
											return 1;
										}
										return 0;
									})[0]
										.Videos?.sort((a, b) => {
											const nameA = a.name?.toLowerCase();
											const nameB = b.name?.toLowerCase();
											if (nameA < nameB) {
												return -1;
											}
											if (nameA > nameB) {
												return 1;
											}
											return 0;
										})[0]
										.name.replace(/\s+/g, "_")}
								>
									<button
										onClick={() => {
											handleVideoSelection(
												course.selectedCourse?.Modules[0]?.Videos[0],
												course.selectedCourse?.Modules[0]
											);
										}}
									>
										Go to Course
									</button>
								</Link>
								<div className={styles.checkoutModules}>
									<div className="">This course includes:</div>
									<div className="">
										{course.selectedCourse?.Modules?.length} Modules and{" "}
										<span>
											{course.selectedCourse?.Modules?.map(
												(module) => module.Videos.length
											).reduce((curr, acc) => acc + curr, 0)}{" "}
											Videos
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
};
