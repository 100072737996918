import { useEffect } from "react";
import { useCourse } from "../../contexts";
import styles from "./VideoDescription.module.css";
import { FcNext } from "react-icons/fc";

export const VideoDescription = () => {
	const { course } = useCourse();
	useEffect(() => {
		document.title = `${course.selectedVideo?.name} | AM-Varsity `;
	}, []);
	return (
		<div className={styles.descriptionContainer}>
			<h1>About the Video</h1>
			<div className={styles.descriptionHeading}>
				<span>{course.selectedModule?.Module_Name} </span> <FcNext />
				<span className="">{course.selectedVideo?.name}</span>
			</div>
			<div className={styles.descriptionContent}>
				{course.selectedVideo?.description ??
					`Lorem ipsum dolor sit amet
				consectetur adipisicing elit. Minus enim explicabo quam, assumenda ab
				architecto ea dolor pariatur mollitia nesciunt corporis tempore
				dignissimos iure blanditiis culpa nostrum officiis vitae accusamus.
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore est
				mollitia fugit in sed ab soluta autem adipisci minima culpa, eos
				laudantium possimus voluptatibus provident saepe suscipit consequatur,
				nobis blanditiis! Ducimus, fugiat facilis voluptas dignissimos
				accusantium in a, amet iusto quasi id suscipit? Reiciendis hic animi
				pariatur necessitatibus magnam at assumenda quisquam minus enim illum,
				dolorum eaque maiores porro ipsam. Laboriosam sequi aspernatur,
				dignissimos id placeat numquam doloribus maiores facere velit soluta
				corporis nesciunt alias hic odio nemo rerum odit beatae aperiam
				repudiandae praesentium obcaecati veniam corrupti! Repellat, laborum
				corporis? Incidunt enim, ratione doloremque odit sapiente aspernatur
				voluptatibus molestiae harum voluptatum, vero reprehenderit. Earum ea ab
				inventore modi commodi? Repellat accusamus deleniti optio magni porro
				nihil eaque cupiditate ratione unde! Quia maxime vitae, eos doloribus
				dolore voluptas, unde, quisquam id totam animi quae! Expedita quo a
				quod, rem doloribus quibusdam, quasi quis ipsum sequi harum vitae
				necessitatibus, molestiae deleniti dolores!`}
			</div>
		</div>
	);
};
